import { styled } from "Theme/stitches.config";
import PrimaryButton from "DesignComponents/Atoms/Buttons/PrimaryButton";
import SecondaryButton from "DesignComponents/Atoms/Buttons/SecondaryButton";
import BodyText from "DesignSystem/Typography/BodyText/BodyText";
import { ModalFooterLayoutOption } from "Enums/ModalFooterLayout.enum";
import { useEffect, useRef } from "react";
import Button from "Shared/Components/Buttons/Button";
import { useTheme } from "Theme/CustomThemeProvider";
import darkColors from "Theme/Dark/dark.colors";
import lightColors from "Theme/Light/light.colors";

export interface ModalFooterButton {
  disabled?: boolean;
  size?: 's' | 'm';
  text: string;
  onClick: () => void;
}

export interface ModalFooterContent {
  footerLayout?: ModalFooterLayoutOption;
  text?: string;
  primaryButton?: ModalFooterButton;
  secondaryButton?: ModalFooterButton;
  additionalInfo?: JSX.Element;
}

export interface ModalFooterProps {
  getModalFooterHeight?: (footerHeight: number | undefined) => void;
  displayModal?: boolean;
  modalFooterContent?: ModalFooterContent;
}

const ModalFooter = ({
  getModalFooterHeight,
  displayModal,
  modalFooterContent,
}: ModalFooterProps) => {
  const { theme } = useTheme();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (displayModal) {
      setTimeout(() => {
        if (getModalFooterHeight)
          getModalFooterHeight(ref.current?.offsetHeight);
      }, 310); // Wait for transition to finish then get the height from footer
    }
  }, [displayModal]);

  if (!modalFooterContent) return null;

  return (
    <Footer
      ref={ref}
      footerLayout={modalFooterContent.footerLayout}
      css={{
        '&::before': {
          background:
            theme === 'light'
              ? lightColors.decorationGradientOpacity
              : theme === 'dark'
              ? darkColors.decorationGradientOpacity
              : '',
        },
      }}
    >
      {modalFooterContent.footerLayout === 'stacked' &&
        modalFooterContent.additionalInfo &&
        modalFooterContent.additionalInfo}

      <ActionArea footerLayout={modalFooterContent.footerLayout}>
        {modalFooterContent.primaryButton && (
          <Action footerLayout={modalFooterContent.footerLayout}>
            <Button<typeof PrimaryButton>
              element={'PrimaryButton'}
              props={{
                text: modalFooterContent.primaryButton.text,
                color: 'Regular',
                hug:
                  modalFooterContent.footerLayout === 'stacked'
                    ? 'width'
                    : undefined,
              }}
              onClick={modalFooterContent.primaryButton?.onClick}
            />
          </Action>
        )}
        {modalFooterContent.secondaryButton && (
          <Action>
            <Button<typeof SecondaryButton>
              element={'SecondaryButton'}
              props={{
                text: modalFooterContent.secondaryButton.text,
                color: 'Regular',
                hug:
                  modalFooterContent.footerLayout === 'stacked'
                    ? 'width'
                    : undefined,
              }}
              onClick={modalFooterContent.secondaryButton?.onClick}
            />
          </Action>
        )}
      </ActionArea>
      {modalFooterContent.text &&
        modalFooterContent.footerLayout === 'sideBySide' && (
          <BodyText>{modalFooterContent.text}</BodyText>
        )}
    </Footer>
  );
};

export default ModalFooter;

const Footer = styled('div', {
  position: 'fixed',
  b: 0,
  backgroundColor: '$surface',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignSelf: 'flex-start',
  alignItems: 'center',
  w: '100%',
  h: 'auto',
  p: '$s150',
  borderTop: '$borders$default solid $interactiveBorderSeparator',

  variants: {
    footerLayout: {
      stacked: {
        flexDirection: 'column',
        p: '$s150',
      },
      sideBySide: {},
    },
  },
});

const ActionArea = styled('div', {
  display: 'flex',
  variants: {
    footerLayout: {
      stacked: {
        flexDirection: 'column',
        w: '100%',
      },
      sideBySide: {
        flexDirection: 'row-reverse',
      },
    },
  },
});

const Action = styled('div', {
  display: 'flex',
  variants: {
    footerLayout: {
      stacked: {
        '&:not(:last-child)': {
          mb: '$s50',
        },
      },
      sideBySide: {
        '&:not(:last-child)': {
          ml: '$s50',
        },
      },
    },
  },
});

export {
  Footer as ModalFooterStyled,
  ActionArea as ModalFooterActionAreaStyled,
  Action as ModalFooterActionStyled,
};
