import PrimaryButton from "DesignComponents/Atoms/Buttons/PrimaryButton";
import SecondaryButton from "DesignComponents/Atoms/Buttons/SecondaryButton";
import BodyText from "DesignSystem/Typography/BodyText/BodyText";
import { useEffect, useRef } from "react";
import Button from "Shared/Components/Buttons/Button";
import { useTheme } from "Theme/CustomThemeProvider";
import darkColors from "Theme/Dark/dark.colors";
import lightColors from "Theme/Light/light.colors";
import {
  ModalFooterActionAreaStyled, ModalFooterActionStyled, ModalFooterProps, ModalFooterStyled
} from "DesignComponents/Organisms/Modal/ModalFooter";
import { useTranslationData } from "Shared/Providers/TranslationProvider";
import { useUiState } from "Shared/Providers/UiState/UiStateProvider";
import { API_ROOT_PATH } from "Shared/Constants/route";
import { SetShippingDestination } from "./ShippingDestination";
import { FetchCartAndNotifyAll } from "Commerce/Cart/Cart";
import { useAppSettingsData } from "Shared/Providers/AppSettingsProvider";

export const SET_SHIPPINGDESTINATION_URL =
  API_ROOT_PATH + '/shipment/SetShippingDestination';

const CountryPickerModalFooter = ({
  getModalFooterHeight,
  displayModal,
  modalFooterContent,
}: ModalFooterProps) => {
  const { theme } = useTheme();
  const ref = useRef<HTMLInputElement>(null);
  const { languageRoute } = useAppSettingsData();
  const { country, toggleModal } = useUiState();
  const {
    commonLabels: { cancel, chooseCountry },
  } = useTranslationData();

  useEffect(() => {
    if (displayModal) {
      setTimeout(() => {
        if (getModalFooterHeight)
          getModalFooterHeight(ref.current?.offsetHeight);
      }, 310); // Wait for transition to finish then get the height from footer
    }
  }, [displayModal]);

  if (!modalFooterContent) return null;

  return (
    <ModalFooterStyled
      ref={ref}
      footerLayout={modalFooterContent.footerLayout}
      css={{
        '&::before': {
          background:
            theme === 'light'
              ? lightColors.decorationGradientOpacity
              : theme === 'dark'
              ? darkColors.decorationGradientOpacity
              : '',
        },
      }}
    >
      <ModalFooterActionAreaStyled
        footerLayout={modalFooterContent.footerLayout}
      >
        <ModalFooterActionStyled footerLayout={modalFooterContent.footerLayout}>
          <Button<typeof PrimaryButton>
            element={'PrimaryButton'}
            props={{
              text: chooseCountry,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={() => {
              SetShippingDestination(country.code);
              FetchCartAndNotifyAll(languageRoute);
              toggleModal(false);
            }}
          />
        </ModalFooterActionStyled>

        <ModalFooterActionStyled>
          <Button<typeof SecondaryButton>
            element={'SecondaryButton'}
            props={{
              text: cancel,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={() => {
              toggleModal(false);
            }}
          />
        </ModalFooterActionStyled>
      </ModalFooterActionAreaStyled>
      {modalFooterContent.text &&
        modalFooterContent.footerLayout === 'sideBySide' && (
          <BodyText>{modalFooterContent.text}</BodyText>
        )}
    </ModalFooterStyled>
  );
};

export default CountryPickerModalFooter;
