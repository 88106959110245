import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { canUseDOM } from "Shared/DOM/WindowHelper";

import { EventDispatcher, SET_THEME } from "../Shared/Common/EventDispatcher";
import { useAppSettingsData } from "../Shared/Providers/AppSettingsProvider";

import dark from "./Dark/dark.theme";
import light from "./Light/light.theme";
import { styled } from "./stitches.config";
import { createTheme, ThemeProvider } from "@mui/material";

type PropType = {
  theme?: ThemeKey;
  children: React.ReactNode;
};

const loadableThemes = {
  dark: dark,
  light: light
};

type ThemeKey = keyof typeof loadableThemes | "";

const isThemeKey = (key: string | null): key is ThemeKey =>
  key === "" || (
    !!key && Object.keys(loadableThemes).includes(key)
  );

const asThemeKey = (key: string | null): ThemeKey =>
  isThemeKey(key) ? key : "light";

type ThemeType = {
  theme?: ThemeKey;
};

const ThemeContext = React.createContext<ThemeType>({} as ThemeType);

const matTheme = createTheme({
  typography: { fontFamily: "Jakarta, Arial, sans-serif" },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        }
      }
    }
  }
});

export const CustomThemeProvider = ({ children }: PropType) => {
  const baseTheme: ThemeKey = asThemeKey(useAppSettingsData().siteTheme);

  const [siteTheme, setTheme] = useState<ThemeKey>(getTheme() || baseTheme);

  const onThemeSwitch = () => {
    storeTheme(siteTheme === "light" ? "dark" : "light", setTheme);
  };

  useEffect(() => {
    EventDispatcher.subscribe(SET_THEME, onThemeSwitch);

    return () => {
      EventDispatcher.unsubscribe(SET_THEME, onThemeSwitch);
    };
  });

  return (
    <ThemeProvider theme={matTheme}>
      <ThemeContext.Provider value={{ theme: siteTheme }}>
        <RootColors
          className={
            siteTheme !== "" ? `${loadableThemes[siteTheme]}` : `${baseTheme}`
          }
        >
          {children}
        </RootColors>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

const storeTheme = (
  theme: ThemeKey,
  callback: Dispatch<SetStateAction<ThemeKey>>,
  baseTheme?: ThemeKey
) => {
  if (baseTheme) {
    const storedTheme = localStorage.getItem("theme");

    if (isThemeKey(storedTheme)) {
      callback(
        baseTheme !== storedTheme && storedTheme ? storedTheme : baseTheme
      );
    }

    return;
  }
  localStorage.setItem("theme", theme);
  callback(theme);
};

export const getTheme = (): ThemeKey => {
  if (canUseDOM() && window.localStorage) {
    const theme = localStorage.getItem("theme");

    if (isThemeKey(theme)) {
      return theme;
    }
  }
  return "light";
};

export const useTheme = (): ThemeType => {
  return useContext(ThemeContext) as ThemeType;
};

const RootColors = styled("div", {
  backgroundColor: "$surface"
});
