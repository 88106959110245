import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import Button from 'Shared/Components/Buttons/Button';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'Theme/stitches.config';
import { useState } from 'react';
import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions';
import { UpdateUserAgreement } from '../Checkout';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import ValidationResultModel from 'Models/Validation/ValidationResultModel.interface';
import { mapFluentValidationErrors } from 'Shared/Common/VariationHelpers';
import { CHECKOUT_UPDATE_FORM, EventDispatcher } from 'Shared/Common/EventDispatcher';
import UserAgreementsModel from 'Models/Checkout/CustomerDetails/UserAgreementsModel.interface';
import { GetCart } from 'Commerce/Cart/Cart';
import useCurrentPage from '../../../../../../Shared/Hooks/useCurrentPage';
import CheckoutPageModel from '../../../../../../Models/Pages/CheckoutPage/CheckoutPageModel.interface';

type PropTypes = {
  goToNextStep: (agree: boolean) => void;
  active: boolean;
  open: boolean;
};

const ConfirmationStep = ({ goToNextStep, active }: PropTypes) => {
  const { languageRoute } = useAppSettingsData();
  const [checked, setChecked] = useState(false);
  const [newsletter, setNewsletter] = useState(true);
  const { cart } = GetCart(languageRoute);
  const { newsletterSignupText } = useCurrentPage<CheckoutPageModel>();

  const [inValid, setInvalid] = useState<
    Record<keyof UserAgreementsModel, string>
  >({} as Record<keyof UserAgreementsModel, string>);

  const {
    checkoutLabels: { goToPayment, iAccept, pay }
  } = useTranslationData();

  const nextActionLabel =
    cart?.totalPriceLeftToPay?.price === 0 ? pay : goToPayment;

  const TermsAndConditionsDiv = () => {
    return (
      <>
        {iAccept + ' '}&nbsp; <TermsAndConditions />.
      </>
    );
  };

  const submit = () => {
    const payload = {
      subscribeToNewsletter: newsletter,
      termsAccepted: checked
    };

    UpdateUserAgreement(
      payload as unknown as UserAgreementsModel,
      languageRoute
    )
      .then((res) => {
        if (res && res.ok) {
          EventDispatcher.dispatch(
            CHECKOUT_UPDATE_FORM,
            'agreementsModel',
            payload
          );
          goToNextStep(checked);
        } else {
          res.json().then((data: ValidationResultModel) => {
            if (!data.isValid && data.errors && Array.isArray(data.errors)) {
              setInvalid(
                mapFluentValidationErrors<
                  Record<keyof UserAgreementsModel, string>
                >(data.errors)
              );
            }
          });
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  return (
    <Root>
      {
        newsletterSignupText &&
        <Checkbox
          isChecked={newsletter}
          onCheck={() => setNewsletter((prev) => !prev)}
          children={() => <div dangerouslySetInnerHTML={{ __html: newsletterSignupText }}></div>}
          labelCss={{ alignItems: 'flex-start' }}
        />
      }
      <Checkbox
        button
        isChecked={checked}
        onCheck={() => setChecked((prev) => !prev)}
        children={TermsAndConditionsDiv}
      />
      {active && (
        <Button<typeof PrimaryButton>
          element="PrimaryButton"
          props={{
            text: nextActionLabel,
            color: 'Regular',
            hug: 'width',
            disabled: !checked || !!inValid['termsAccepted']
          }}
          onClick={submit}
        />
      )}
    </Root>
  );
};

const Root = styled('form', {
  marginTop: '$s250',
  display: 'flex',
  flexDirection: 'column',
  gap: '$s100'
});

export default ConfirmationStep;
