import PrimaryButton from "DesignComponents/Atoms/Buttons/PrimaryButton";
import SecondaryButton from "DesignComponents/Atoms/Buttons/SecondaryButton";
import BodyText from "DesignSystem/Typography/BodyText/BodyText";
import { useCallback, useEffect, useRef } from "react";
import Button from "Shared/Components/Buttons/Button";
import { useTheme } from "Theme/CustomThemeProvider";
import darkColors from "Theme/Dark/dark.colors";
import lightColors from "Theme/Light/light.colors";
import { useFilterData } from "Shared/Providers/FilterProvider";
import { useTranslationData } from "Shared/Providers/TranslationProvider";
import { useUiState } from "Shared/Providers/UiState/UiStateProvider";
import { useAppSettingsData } from "Shared/Providers/AppSettingsProvider";
import { useSearch } from "Cms/Pages/SearchPage/Search";
import {
  ModalFooterActionAreaStyled, ModalFooterActionStyled, ModalFooterProps, ModalFooterStyled
} from "DesignComponents/Organisms/Modal/ModalFooter";
import { FILTER_URL_CONSTANTS } from "Shared/Common/Helpers";

type FilterModalFooterProps = ModalFooterProps & {
  categoryCode?: string;
};

const FilterModalFooter = ({
  getModalFooterHeight,
  modalFooterContent,
  categoryCode,
}: FilterModalFooterProps) => {
  const {
    staticPages: { searchPage },
  } = useAppSettingsData();

  const { result } = useSearch(
    searchPage,
    FILTER_URL_CONSTANTS.DEFAULT_ITEMS,
    categoryCode ? categoryCode : undefined
  );

  const { theme } = useTheme();
  const { toggleModal, modalState } = useUiState();
  const [, dispatchFilterData] = useFilterData();
  const {
    searchLabels: { show, clearFilters },
  } = useTranslationData();

  const totalHits = useCallback(() => {
    if (!result) return 0;

    return (
      (result.articleProducts?.availableItems || 0) +
      (result.magazines?.availableItems || 0) +
      (result.pages?.availableItems || 0) +
      (result.subscriptionOffers?.availableItems || 0)
    );
  }, [result]);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (modalState.display) {
      setTimeout(() => {
        if (getModalFooterHeight) {
          getModalFooterHeight(ref.current?.offsetHeight);
        }
      }, 310); // Wait for transition to finish then get the height from footer
    }
  }, [modalState.display]);

  if (!modalFooterContent) return null;

  return (
    <ModalFooterStyled
      ref={ref}
      footerLayout={modalFooterContent.footerLayout}
      css={{
        '&::before': {
          background:
            theme === 'light'
              ? lightColors.decorationGradientOpacity
              : theme === 'dark'
              ? darkColors.decorationGradientOpacity
              : '',
        },
      }}
    >
      {modalFooterContent.footerLayout === 'stacked' &&
        modalFooterContent.additionalInfo &&
        modalFooterContent.additionalInfo}

      <ModalFooterActionAreaStyled
        footerLayout={modalFooterContent.footerLayout}
      >
        <ModalFooterActionStyled footerLayout={modalFooterContent.footerLayout}>
          <Button<typeof PrimaryButton>
            element={'PrimaryButton'}
            props={{
              text: `${show} ${totalHits()}`,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={() => toggleModal(false)}
          />
        </ModalFooterActionStyled>

        <ModalFooterActionStyled>
          <Button<typeof SecondaryButton>
            element={'SecondaryButton'}
            props={{
              text: clearFilters,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={() => dispatchFilterData({ type: 'onClear' })}
          />
        </ModalFooterActionStyled>
      </ModalFooterActionAreaStyled>
      {modalFooterContent.text &&
        modalFooterContent.footerLayout === 'sideBySide' && (
          <BodyText>{modalFooterContent.text}</BodyText>
        )}
    </ModalFooterStyled>
  );
};

export default FilterModalFooter;
