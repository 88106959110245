import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'Theme/stitches.config';
import { ProductUSP } from './ProductUSP';
import ProductCTA from './ProductCTA';

import { useVariantData } from 'Shared/Providers/VariantProvider';
import { Variant } from 'Enums/Variant.enum';
import { useEffect, useState, ChangeEvent } from 'react';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';

import Heading from 'DesignSystem/Typography/Headings/Heading';
import IconBox from 'DesignComponents/Molecules/IconBox/IconBox';
import SubscriptionOfferProductBasePageModel from 'Models/Pages/ProductPage/SubscriptionOffer/SubscriptionOfferProductBasePageModel.interface';
import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import SubscriptioOfferPriceExplaination from './SubscriptionOfferPriceExplaination';

type PropTypes = {
  productPage: SubscriptionOfferProductBasePageModel;
  displayProductCTA: boolean;
  openFirstAccordionItem?: () => void;
};

function SubscriptionAttributeArea({
  productPage,
  displayProductCTA = true,
  openFirstAccordionItem,
}: PropTypes) {
  const {
    productLabels: { shortDescriptionReadMoreText, productUsps },
    subscriptionOfferLabels: { includeGimmickCheckboxText },
  } = useTranslationData();
  const { variantDispatch } = useVariantData();
  const { variationCollection } = productPage;
  const [variant, setVariant] = useState<VariationModel>();
  const [showGimmickCheckbox, setShowGimmickCheckbox] = useState<boolean>();
  const [userWantsGimmick, setUserWantsGimmick] = useState<boolean>();
  const [variantSelectListOptions] = useState<VariationModel[]>(
    variationCollection.filter(
      (variant) => variant.subscriptionOfferDetails.showInSelectList
    )
  );

  useEffect(() => {
    const defaultVariant = getDefaultVariant();
    setVariant(defaultVariant);
    variantDispatch({
      type: Variant.SET_INITIAL,
      selectedVariant: defaultVariant,
      hasSizes: false,
    });
    setShowGimmickCheckbox(shouldDisplayGimmickCheckbox(defaultVariant.code));
    setUserWantsGimmick(false);
  }, []);

  function shouldDisplayGimmickCheckbox(variantCode?: string) {
    return findVariantWithGimmick(variantCode) != null;
  }

  function getDefaultVariant() {
    return (
      variationCollection.find(
        (variant) => !variant.subscriptionOfferDetails.hasGimmick
      ) ?? variationCollection[0]
    );
  }

  function findVariantWithGimmick(variantCode?: string) {
    if (!variantCode) {
      return null;
    }

    return variationCollection.find(
      (variant) =>
        variant.subscriptionOfferDetails.twinVariant == variantCode &&
        variant.subscriptionOfferDetails.hasGimmick
    );
  }

  function findVariantWithFallback(variantCode: string) {
    return (
      variationCollection.find((variant) => variant.code == variantCode) ??
      getDefaultVariant()
    );
  }

  function setSelectVariant(selectedVariant: VariationModel) {
    setVariant(selectedVariant);

    variantDispatch({
      type: Variant.SET_INITIAL,
      selectedVariant: selectedVariant,
      hasSizes: false,
    });
  }

  function handleVariantChange(e: ChangeEvent<HTMLSelectElement>) {
    const variantWithGimmick = findVariantWithGimmick(e.target.value);
    const variantWithoutGimmick = findVariantWithFallback(e.target.value);
    const selectedVariant =
      userWantsGimmick && variantWithGimmick
        ? variantWithGimmick
        : variantWithoutGimmick;

    setSelectVariant(selectedVariant);

    if (
      variantWithGimmick?.subscriptionOfferDetails.hasGimmick ||
      variantWithoutGimmick?.subscriptionOfferDetails.hasGimmick
    ) {
      setShowGimmickCheckbox(true);
    } else {
      setShowGimmickCheckbox(false);
      setUserWantsGimmick(false);
    }
  }

  function handleGimmickCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    setUserWantsGimmick(e.target.checked);
    const variantWithGimmick = findVariantWithGimmick(variant?.code);

    if (e.target.checked && variantWithGimmick) {
      setSelectVariant(variantWithGimmick);
      return;
    }

    if (variant?.subscriptionOfferDetails.hasGimmick) {
      setSelectVariant(
        findVariantWithFallback(variant?.subscriptionOfferDetails.twinVariant)
      );
    }
  }

  return (
    <AttributeContainer>
      <AttributeWrapper>
        <Heading size={'l'} tag="h1" color="Regular" css={{ mb: '$s100' }}>
          {productPage.displayName}
          {productPage.nameOfAdditionalItems && (
            <SubHeading>{'+ ' + productPage.nameOfAdditionalItems}</SubHeading>
          )}
        </Heading>
        {productPage.shortDescription && (
          <BodyText size="l">
            {productPage.shortDescription}
            {productPage.hasLongDescription && (
              <>
                {' '}
                <ReadMore
                  onClick={(e) => {
                    e.preventDefault();
                    if (openFirstAccordionItem) {
                      openFirstAccordionItem();
                    }
                  }}
                >
                  {shortDescriptionReadMoreText}
                </ReadMore>
              </>
            )}
          </BodyText>
        )}

        {variantSelectListOptions.length > 1 && (
          <select onChange={(e) => handleVariantChange(e)}>
            {variantSelectListOptions.map((variant) => (
              <option key={variant.code} value={variant.code}>
                {variant.nameOfAdditionalItems}
              </option>
            ))}
          </select>
        )}

        {showGimmickCheckbox && (
          <Checkbox
            isChecked={userWantsGimmick ?? false}
            text={includeGimmickCheckboxText}
            onCheck={(e) => handleGimmickCheckboxChange(e)}
          />
        )}

        {displayProductCTA && <ProductCTA />}
        <ProductBoxes>
          {variant?.freightAdditionalInfo && (
            <IconBox
              icon="Shipping"
              title={variant.freightAdditionalInfo}
            ></IconBox>
          )}
          {variant && (
            <IconBox
              icon="Subscription"
              title={variant.additionalInfo}
            ></IconBox>
          )}

          <SubscriptioOfferPriceExplaination />

          <ProductUSP
            productUsp={productPage.usp}
            globalUsp={productPage.globalUsp}
            label={productUsps}
          />
        </ProductBoxes>
      </AttributeWrapper>
    </AttributeContainer>
  );
}

const SubHeading = styled('div', {
  fontSize: '$fontSize500',
  fontFamily: 'fontSemiBold',
  color: '$onSurface',
});

const AttributeContainer = styled('div', {});

const AttributeWrapper = styled('div', {
  w: '100%',
});

const ReadMore = styled('a', {
  color: '$emphasisedLinkColor',
  fontFamily: 'fontSemiBold',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationThickness: '1px',
    cursor: 'pointer',
  },
});

const ProductBoxes = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export default SubscriptionAttributeArea;
