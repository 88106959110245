import { useCallback, useEffect, useState } from 'react';
import { FILTER_URL_CONSTANTS } from 'Shared/Common/Helpers';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'Theme/stitches.config';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useSearch } from './Search';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import SearchInput from 'DesignComponents/Atoms/Input/SearchInput';
import { useFilterData } from 'Shared/Providers/FilterProvider';
import SearchNoResult from 'Cms/Pages/SearchPage/SearchNoResult/SearchNoResult';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import SearchPageModel from 'Models/Pages/SearchPage/SearchPageModel.interface';
import SearchOrderSort from './SearchOrderSort/SearchOrderSort';
import SearchResult from './SearchResult/SearchResult';
import SearchFilterFacets from './SearchFilterFacets/SearchFilterFacets';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';

const SearchWrapper = () => {
  if (!canUseDOM()) {
    return <></>;
  }

  return <SearchPage />;
};

function SearchPage() {
  const kexNavigate = useKexNavigate();
  const [filterState, dispatchFilterData] = useFilterData();
  const isMobile = useMedia(mediaQueryTypes.bpMax720);
  const {
    staticPages: { searchPage },
  } = useAppSettingsData();
  const { contentArea } = useCurrentPage<SearchPageModel>();
  const {
    searchLabels: {
      loadMore,
      youSearchedFor: youSearchedForText,
      hits: hitsText,
      hitsOn: hitsOnText,
      search: searchText,
    },
  } = useTranslationData();

  const {
    result,
    noResult,
    isLoading,
    loadingMore,
    paginate,
    facets,
    sorters,
  } = useSearch(searchPage, FILTER_URL_CONSTANTS.DEFAULT_ITEMS);

  const [newQuery, setNewQuery] = useState('');

  const searchQuery = (): string => {
    const [, query] = filterState.queryParams.find(
      ([key]) => key === FILTER_URL_CONSTANTS.SEARCH_QUERY
    ) || [FILTER_URL_CONSTANTS.SEARCH_QUERY, ''];

    return query;
  };

  const productHits = useCallback(() => {
    if (!result) return 0;
    return result.articleProducts?.items.length || 0;
  }, [result]);

  const totalHits = useCallback(() => {
    if (!result) return 0;
    return (
      (result.articleProducts?.availableItems || 0) +
      (result.magazines?.availableItems || 0) +
      (result.pages?.availableItems || 0) +
      (result.subscriptionOffers?.availableItems || 0)
    );
  }, [result]);

  const doSearch = () => {
    if (newQuery.length > 1) {
      dispatchFilterData({ type: 'setQuery', value: newQuery });
      kexNavigate(`${searchPage}?query=${newQuery}`);
    }
  };

  // disableButton, applicable only on "Load more" button connected to Article Products result
  const disableLoadMoreArticleProductsButton = useCallback(() => {
    return (
      result?.articleProducts?.items.length ===
      result?.articleProducts?.availableItems
    );
  }, [result]);

  useEffect(() => {
    const query = searchQuery();
    setNewQuery(query);

    dispatchFilterData({ type: 'setQuery', value: query });
  }, [searchQuery()]);

  useEffect(() => {
    if (facets && sorters)
      dispatchFilterData({ type: 'setFacets', facets, sorters });
  }, [facets, sorters, dispatchFilterData]);
  return (
    <>
      <ContentContainer>
        {!isMobile && (
          <SearchTermDisplay>
            <Heading tag="h1" css={{ mb: 4 }} size="xl">
              {`${youSearchedForText} "${filterState.query}"`}
            </Heading>
          </SearchTermDisplay>
        )}

        <LayoutWrapper>
          {result && !noResult && (
            <FilterContainer>
              {isMobile ? (
                <SearchInput
                  query={newQuery}
                  setQuery={setNewQuery}
                  doSearch={doSearch}
                  css={{ my: 8 }}
                  placeholder={searchText}
                />
              ) : (
                result.facets && <SearchFilterFacets facets={result.facets} />
              )}
            </FilterContainer>
          )}

          {result && noResult && isMobile && (
            <FilterContainer>
              <SearchInput
                query={newQuery}
                setQuery={setNewQuery}
                doSearch={doSearch}
                css={{ my: 8 }}
              />
            </FilterContainer>
          )}

          <SearchResultContainer>
            {result ? (
              <>
                {!noResult && (
                  <SearchOrderSort
                    sorters={result.sorters}
                    searchHits={totalHits()}
                    searchHitsText={
                      isMobile
                        ? `${totalHits()} ${hitsText}`
                        : `${totalHits()} ${hitsOnText} ${searchQuery()}`
                    }
                    facets={result.facets}
                  />
                )}
                <SearchResult
                  result={result}
                  isLoading={isLoading}
                  loadMoreObj={{
                    productHits: productHits(),
                    loadMore: loadMore,
                    disableButton: disableLoadMoreArticleProductsButton(),
                    onClick: paginate,
                    loadingMore: loadingMore,
                  }}
                />
              </>
            ) : (
              <Padding />
            )}

            {noResult && !isLoading && (
              <>
                <SearchNoResult />
                {Array.isArray(contentArea) && (
                  <ContentArea childItems={contentArea} />
                )}
              </>
            )}
          </SearchResultContainer>
        </LayoutWrapper>
      </ContentContainer>
    </>
  );
}

export default SearchWrapper;

const SearchTermDisplay = styled('div', {
  pt: 16,
  pb: 4,
  mb: 10,
  borderBottom: '1px solid $staticBorderDefault',
});

const LayoutWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@bpMin721': {
    flexDirection: 'row',
    gap: '32px',
  },
  '@bpMin1150': { gap: '80px' },
});

const FilterContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  backgroundColor: '$backgroundPrimary',
  borderBottomStyle: 'solid',
  borderBottomColor: '$searchPageBorderPrimary',
  '@bpMin721': {
    w: 260,
    pb: 12,
  },
  '@bpMin1150': { w: 260 },
});

const SearchResultContainer = styled('div', {
  pb: 10,
  w: '100%',
});

const Padding = styled('div', {
  height: '400px',
});
