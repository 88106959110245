import React, { useMemo } from 'react';
import { ReactNode, useReducer } from 'react';
import { getSelectedModel } from 'Shared/Common/Helpers';
import { useTranslationData } from './TranslationProvider';
import { FilterMessage } from 'Commerce/Organisms/FilterComponent/filterMessages';
import { filterReducer } from 'Commerce/Organisms/FilterComponent/FilterReducer/filterReducer';
import {
  convertStringToMap,
  toQueryParams,
  createSorterModelFromQueryString,
} from 'Commerce/Organisms/FilterComponent/FilterReducer/filterReducerHelperFunctions';
import { FilterState } from 'Commerce/Organisms/FilterComponent/FilterReducer/filterState';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

type Props = {
  children: ReactNode;
  urlSearchString: string;
  noQuery?: true;
};

type FilterContextType = [FilterState, React.Dispatch<FilterMessage>];

export const FilterContext = React.createContext(
  [] as unknown as FilterContextType
);

export const useFilterData = () => React.useContext(FilterContext);

export const FilterProvider = ({
  children,
  urlSearchString,
  noQuery,
}: Props) => {
  const {
    searchLabels: { sortOrderLatest, sortOrderPriceAsc, sortOrderNameAsc },
  } = useTranslationData();

  const orderTranslations = {
    '0': sortOrderLatest,
    '1': sortOrderPriceAsc,
    '2': sortOrderNameAsc,
  };

  const INIT: FilterState = useMemo(() => {
    return {
      data: null,
      multiSelectFilters: convertStringToMap(urlSearchString),
      initSorterFilter: {
        selected: true,
        text: sortOrderLatest,
        value: 0,
      },
      sorterFilter: createSorterModelFromQueryString(
        canUseDOM() ? window.location.search : urlSearchString,
        orderTranslations
      ) || {
        selected: true,
        text: sortOrderLatest,
        value: 0,
      },
      queryParams: toQueryParams(
        canUseDOM() ? window.location.search : urlSearchString
      ),
      query: noQuery ? '' : '',
      selectedModel: getSelectedModel(),
      page: 'search',
    };
  }, [urlSearchString, noQuery]);

  const [filterState, dispatch] = useReducer(filterReducer, INIT);

  const value = useMemo(
    (): FilterContextType => [filterState, dispatch],
    [filterState, dispatch]
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};
